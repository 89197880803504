import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import { StoreMe } from "store-me";
import ReactDOM from "react-dom";

import guests_state from "./_constants/static_guests_response";
import GlobalStyle from "./styles/GlobalCSS";
import App from "./App";

const stateFromSpreadSheet = {
  guests: [],
  party_hour: undefined,
  signatures_hour: undefined,
  google_form: undefined,
  event_space_location: undefined,
  signature_room_location: undefined,
  invite_headline: undefined,
  invite_long_text_multiple_guests: undefined,
  invite_long_text_single_guest: undefined,
  invite_welcome_multiple_guests: undefined,
  invite_welcome_single_guest: undefined,
  notes_books_instead_of_flowers: undefined,
  note_cash_is_welcome: undefined,
  notes_save_this_link: undefined,
  notes_good_to_know: undefined,
};

const initialState = {
  fullNames: "Любомира и Марио",
  weddingDate: "25.06.2022",
  weddingDateWithLetters: "25 Юни 2022",
  bestMan: "Диян Чубуров",
  bestWoman: "Елина Рашкова",
  ritualHallName: 'Обредна зала "Триадица"',
  ritualHallAddress: 'бул. "Витоша" 15',
  weddingPlaceName: '"Event Space"',
  weddingPlaceAddress: 'бул. "България" 1',
  resourcesLoaded: false,
  loaderFaded: false,
  guestsLoaded: false,
  ...stateFromSpreadSheet,
};

ReactDOM.render(
  <StrictMode>
    <StoreMe initialState={{ ...initialState, ...guests_state }}>
      <GlobalStyle />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreMe>
  </StrictMode>,
  document.getElementById("root")
);

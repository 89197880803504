import axios from "axios";

window.cachedImages = {};

const loadResources = (resourcesToLoad = []) =>
  Promise.all(
    resourcesToLoad.map((filePath) => {
      return new Promise(async (resolve) => {
        try {
          const { data } = await axios.get(filePath);
          const image = new Image();

          image.src = data;
          window.cachedImages[filePath] = image;

          resolve(true);
        } catch (error) {
          resolve(true);
        }
      });
    })
  );

export default loadResources;

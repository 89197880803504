import { createGlobalStyle } from "styled-components";

import normalizeCSS from "./normalizeCSS";

/* Note that font family CSS is located in public/index.html file */

const GlobalStyle = createGlobalStyle`
  ${normalizeCSS}

  html,
  body {
    height: 100%;
    margin: 0;
    line-height: 1;
  }

  * {
    box-sizing: border-box;
    user-select: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    position: relative;
    min-height: 100%;
  }

  .dark-gold {
    color: #d6a800;
  }

  .light-gold {
    color: #e9b600;
  }

  .txt-gold-gradient {
    background: -webkit-linear-gradient(#ffd337, #ffe589, #e3b203);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f9f5;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #83b8a6;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #4f8c77;
  }

  .react-photo-gallery--gallery {
    & > div {
      img {
        object-fit: cover;
      }
    }
  }

  @keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fade-in-slide-up {
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0); }
  }

  @keyframes left-flower-animation {
    0% { opacity: 0; transform: translate(-16%, 3%) rotate(-10deg); }
    100% { opacity: 1; transform: translate(0%, 0%) rotate(0deg); }
  }

  @keyframes right-flower-animation {
    0% { opacity: 0; transform: translate(16%, 3%) rotate(10deg); }
    100% { opacity: 1; transform: translate(0%, 0%) rotate(0deg); }
  }

  @keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes loader-spin {
    0% { transform: rotate(-25deg); }
    100% { transform: rotate(0deg); }
  }
`;

export default GlobalStyle;

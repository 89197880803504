const SuitIcon = ({ className }) => (
  <svg
    className={className}
    height="128"
    width="128"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <path d="M53.25,25.13l-7.69-4a1,1,0,1,0-.92,1.77l6.24,3.25L44.64,29.4a1,1,0,0,0,.46,1.88,1,1,0,0,0,.46-.11l7.69-4a1,1,0,0,0,.51-1A1,1,0,0,0,53.25,25.13Z" />
    <path d="M83.79,21.57a1,1,0,0,0-1.35-.43l-7.69,4a1,1,0,0,0-.52,1,1,1,0,0,0,.52,1l7.69,4a1,1,0,0,0,.46.11,1,1,0,0,0,.46-1.88l-6.24-3.24,6.24-3.25A1,1,0,0,0,83.79,21.57Z" />
    <path d="M91.8,9.71a4.53,4.53,0,0,0-3.69.37l-18,9.66a5.14,5.14,0,0,0-3.51-1.39H61.41a5.14,5.14,0,0,0-3.51,1.39l-18-9.64a4.59,4.59,0,0,0-6.51,2.15,35.23,35.23,0,0,0,0,27.81A4.61,4.61,0,0,0,36.2,42.6a4.7,4.7,0,0,0,1.43.23,4.5,4.5,0,0,0,2.26-.6l18-9.66A5.14,5.14,0,0,0,61.41,34h5.18a5.14,5.14,0,0,0,3.51-1.39l18,9.65a4.59,4.59,0,0,0,3.72.38,4.61,4.61,0,0,0,2.79-2.54,35.23,35.23,0,0,0,0-27.81A4.58,4.58,0,0,0,91.8,9.71ZM38.92,40.48a2.54,2.54,0,0,1-2.1.22,2.64,2.64,0,0,1-1.58-1.44,33.27,33.27,0,0,1,0-26.21,2.64,2.64,0,0,1,1.58-1.44,2.6,2.6,0,0,1,2.12.23L56.7,21.36a5.11,5.11,0,0,0-.51,2.2v5.19A5.08,5.08,0,0,0,56.7,31ZM66.59,32H61.41a3.22,3.22,0,0,1-3.22-3.21V23.56a3.22,3.22,0,0,1,3.22-3.21h5.18a3.22,3.22,0,0,1,3.22,3.21v5.19A3.22,3.22,0,0,1,66.59,32Zm26.17,7.3a2.64,2.64,0,0,1-1.58,1.44,2.6,2.6,0,0,1-2.12-.23L71.3,31a5.08,5.08,0,0,0,.51-2.2V23.56a5.11,5.11,0,0,0-.51-2.2l17.78-9.53a2.58,2.58,0,0,1,2.1-.22,2.64,2.64,0,0,1,1.58,1.44,33.27,33.27,0,0,1,0,26.21Z" />
    <path d="M120.43,40.08,103.11,22.76a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l16.61,16.61L104.06,55a1,1,0,0,0,0,1.42l14.29,14.29L78.09,116.52H72.24L91.81,53.67a1,1,0,1,0-1.91-.6l-20,64.15a1,1,0,0,0,1,1.3h7.65a1,1,0,0,0,.75-.34l41.18-46.82a1,1,0,0,0,0-1.37L106.18,55.74l14.25-14.25A1,1,0,0,0,120.43,40.08Z" />
    <path d="M26.3,22.76a1,1,0,0,0-1.41,0L7.57,40.08a1,1,0,0,0,0,1.41L21.82,55.74,7.57,70a1,1,0,0,0,0,1.37l41.18,46.82a1,1,0,0,0,.75.34h7.65a1,1,0,0,0,1-1.3l-20-64.15a1,1,0,1,0-1.91.6l19.57,62.85H49.91L9.65,70.74,23.94,56.45a1,1,0,0,0,0-1.42L9.69,40.79,26.3,24.18A1,1,0,0,0,26.3,22.76Z" />
    <path d="M89.45,97.49a1,1,0,0,0,.65.25,1,1,0,0,0,.76-.34l13-14.93a1,1,0,1,0-1.51-1.31l-13,14.92A1,1,0,0,0,89.45,97.49Z" />
    <path d="M106.9,79l1.54-1.77a1,1,0,0,0-.09-1.41,1,1,0,0,0-1.42.1l-1.54,1.77a1,1,0,0,0,.75,1.66A1,1,0,0,0,106.9,79Z" />
    <path d="M24.08,31.17l-8.66,8.65a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l8.66-8.66a1,1,0,0,0,0-1.41A1,1,0,0,0,24.08,31.17Z" />
    <circle cx="64" cy="51.3" r="2.5" />
    <circle cx="64" cy="68.4" r="2.5" />
    <circle cx="64" cy="85.5" r="2.5" />
  </svg>
);

export default SuitIcon;

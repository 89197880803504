import { Fragment } from "react";

const i18n = (text = "", config = {}) => {
  if (!text || (!text.includes("{{") && !text.includes("<b>"))) {
    return text;
  }

  /* Convert this text: 
    "Sample text with dynamic {{value}} passed with config" to
    ["Sample text with dynamic", "value", "passed with config"]
  */

  let splittedByDynamicParts = text
    .split(/{{(.+?)}}/)
    .filter((textPart) => textPart !== "");
  let result;

  function handleBoldTag() {
    if (text.includes("<b>")) {
      splittedByDynamicParts = splittedByDynamicParts.map((part, i) => {
        const match = part.match(/<b>(.+?)<\/b>/);

        if (match) {
          const boldedText = match[1];
          const splitted = part
            .split(/<b>(.+?)<\/b>/)
            .map((subPart, n) =>
              subPart === boldedText ? (
                <b key={`b-${i}-${n}`}>{subPart}</b>
              ) : (
                subPart
              )
            );

          return splitted;
        } else {
          return part;
        }
      });
    }
  }

  function handleATag() {
    if (text.includes("</a>")) {
      splittedByDynamicParts = splittedByDynamicParts.map((part, i) => {
        const match = part.match(/<a (.+?)>(.+?)<\/a>/);

        if (match) {
          // console.log("part: ", part);
          // console.log(match);

          const anchorAttributes = match[1];
          const anchorText = match[2];
          const splitted = part.split(/<a(.+?)<\/a>/).map((subPart, n) => {
            // console.log("subPart: ", subPart);
            const href = anchorAttributes.match(/href='(.+?)'|href="(.+?)"/);
            const target = anchorAttributes.match(
              /target='(.+?)'|target="(.+?)"/
            );
            const rel = anchorAttributes.match(/rel='(.+?)'|rel="(.+?)"/);

            return subPart.includes(anchorAttributes) ? (
              <a
                href={href?.[1]}
                target={target?.[1]}
                rel={rel?.[1]}
                key={`a-${i}-${n}`}
              >
                {anchorText}
              </a>
            ) : (
              subPart
            );
          });

          return splitted;
        } else {
          return part;
        }
      });
    }
  }

  handleBoldTag();
  handleATag();

  if (!config.new_line) {
    config.new_line = <br />;
  }

  if (config.displayAsList) {
    result = (
      <ul>
        {splittedByDynamicParts.map(
          (part, index) =>
            part !== "new_line" && (
              <li key={`${part}-${index}`}>
                {config.prefix}
                {config[part] || part}
                {config.suffix}
              </li>
            )
        )}
      </ul>
    );
  } else {
    result = splittedByDynamicParts.map((part, index) => (
      <Fragment key={`${part}-${index}`}>{config[part] || part}</Fragment>
    ));
  }

  return result;
};

export default i18n;

const guests_state = {
  event_space_location: "https://goo.gl/maps/XbLvkZsVGrKNSZie9",
  google_form: "https://forms.gle/zQXE6vxbL9kHxfCt9",
  invite_headline: "Покана за",
  // invite_long_text_multiple_guests:
  //   'С вълнение ви каним да споделите с нас денят, в който ще си кажем най-сигурното "да" и заедно да го превърнем в легендарно парти!',
  invite_long_text_multiple_guests:
    "Благодарим ви, че отпразнувахте с нас този важен ден, който нямаше да бъде същия без вашето присъствие!",
  // invite_long_text_single_guest:
  //   'С вълнение те каним да споделиш с нас денят, в който ще си кажем най-сигурното "да" и заедно да го превърнем в легендарно парти!',
  invite_long_text_single_guest:
    "Благодарим ти, че отпразнува с нас този важен ден, който нямаше да бъде същия без присъствие ти!",
  // invite_welcome_multiple_guests: "Заповядайте на сватбата на",
  // invite_welcome_single_guest: "Заповядай на сватбата на",
  invite_welcome_multiple_guests: "Сватбата на",
  invite_welcome_single_guest: "Сватбата на",
  note_cash_is_welcome:
    "За да ви спестим време и усилия, с радост ще приемем вашия подарък в плик",
  notes_books_instead_of_flowers:
    "Вместо цветя ни донесете <b>вашата любима</b> книга, чрез която да ви опознаем по-добре",
  notes_good_to_know:
    'Залата се намира в сградата на "малкото НДК" до входа на кино Люмиер, бившият клуб "Планет" {{new_line}} Районът е "синя зона" до 20ч. Големият паркинг на НДК предлага почасово спиране срещу 2лв/ч или предплатени 15лв/12ч {{new_line}} Ако не потвърдите присъствие до 20.05.2022, моля, носете си сандвич и стол {{new_line}} На място търсете специалните сватбени очила и се снимайте с тях ',
  notes_save_this_link:
    "<b>Запазете този линк</b> - ще бъде обновяван с информация за събитието, както и снимки и видеа след него",
  party_hour: "18:00",
  signature_room_location: "https://goo.gl/maps/LVtjzwuSkGSzvPki7",
  signatures_hour: "16:20",
  guests: [
    {
      cringe_section: 0,
      for_full_day: 1,
      id: 1,
      invitation_is_sent: 1,
      name: "Мая",
      plus_one_name: "Боби",
      plus_two_name: "",
      url: "maya-borisova",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 2,
      invitation_is_sent: 1,
      name: "Боян",
      plus_one_name: "Севи",
      plus_two_name: "Айсел",
      url: "boyan-gaidarski",
    },
    {
      cringe_section: 1,
      for_full_day: 1,
      id: 3,
      invitation_is_sent: 1,
      name: "Антония",
      plus_one_name: "Красимир",
      plus_two_name: "Емона",
      url: "neti-krasi-mo",
    },
    {
      cringe_section: 0,
      for_full_day: 1,
      id: 4,
      invitation_is_sent: 1,
      name: "Неда",
      plus_one_name: "Петър, Сирма",
      plus_two_name: "Кики",
      url: "neda-petur-sirma-kiki",
    },
    {
      cringe_section: 0,
      for_full_day: 1,
      id: 5,
      invitation_is_sent: 1,
      name: "Мама ",
      plus_one_name: "Тати ",
      plus_two_name: "",
      url: "mama-tati",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 6,
      invitation_is_sent: 1,
      name: "Деян",
      plus_one_name: "",
      plus_two_name: "",
      url: "chicho",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 7,
      invitation_is_sent: 1,
      name: "Стела",
      plus_one_name: "",
      plus_two_name: "",
      url: "stela",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 8,
      invitation_is_sent: 1,
      name: "Вера",
      plus_one_name: "",
      plus_two_name: "",
      url: "vera",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 9,
      invitation_is_sent: 1,
      name: "Яни",
      plus_one_name: "Дани",
      plus_two_name: "",
      url: "dani-yani",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 10,
      invitation_is_sent: 1,
      name: "Марги",
      plus_one_name: "Ставри",
      plus_two_name: "",
      url: "stavri-margi",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 11,
      invitation_is_sent: 1,
      name: "Сич",
      plus_one_name: "Снежи",
      plus_two_name: "бебе Стоянова",
      url: "sich-sneji",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 12,
      invitation_is_sent: 1,
      name: "Сталина",
      plus_one_name: "Васил",
      plus_two_name: "",
      url: "vasil-stali",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 13,
      invitation_is_sent: 1,
      name: "Мария",
      plus_one_name: "Лари",
      plus_two_name: "",
      url: "lari-maria",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 14,
      invitation_is_sent: 1,
      name: "Алекс / Али Джи",
      plus_one_name: "",
      plus_two_name: "",
      url: "ali-g",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 15,
      invitation_is_sent: 1,
      name: "Борислава",
      plus_one_name: "Александър",
      plus_two_name: "",
      url: "sahata",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 16,
      invitation_is_sent: 1,
      name: "Лора",
      plus_one_name: "",
      plus_two_name: "",
      url: "lora",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 17,
      invitation_is_sent: 1,
      name: "Димитър",
      plus_one_name: "",
      plus_two_name: "",
      url: "nikov",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 18,
      invitation_is_sent: 1,
      name: "Ивайла",
      plus_one_name: "",
      plus_two_name: "",
      url: "iveto",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 19,
      invitation_is_sent: 1,
      name: "Мони",
      plus_one_name: "Мариян ",
      plus_two_name: "",
      url: "mariyan",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 20,
      invitation_is_sent: 1,
      name: "Елиза",
      plus_one_name: "Александър",
      plus_two_name: "",
      url: "sasheto",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 21,
      invitation_is_sent: 1,
      name: "Ели",
      plus_one_name: "Макс",
      plus_two_name: "",
      url: "eli-max",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 22,
      invitation_is_sent: 0,
      name: "Илко",
      plus_one_name: "",
      plus_two_name: "",
      url: "ilko",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 23,
      invitation_is_sent: 1,
      name: "Радо",
      plus_one_name: "",
      plus_two_name: "",
      url: "rado",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 24,
      invitation_is_sent: 1,
      name: "Мони",
      plus_one_name: "Митака",
      plus_two_name: "",
      url: "mitaka-moni",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 25,
      invitation_is_sent: 1,
      name: "Вержи",
      plus_one_name: "Жоро",
      plus_two_name: "",
      url: "verji-joro",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 26,
      invitation_is_sent: 1,
      name: "Здравка",
      plus_one_name: "Вичо",
      plus_two_name: "",
      url: "vicho-zdravka",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 27,
      invitation_is_sent: 1,
      name: "Радост",
      plus_one_name: "Митко",
      plus_two_name: "",
      url: "radost-mitko",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 28,
      invitation_is_sent: 1,
      name: "Поли",
      plus_one_name: "Ясен",
      plus_two_name: "",
      url: "yasen-poli",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 29,
      invitation_is_sent: 1,
      name: "Милена",
      plus_one_name: "Тони",
      plus_two_name: "",
      url: "toni-milena",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 30,
      invitation_is_sent: 1,
      name: "Петя",
      plus_one_name: "Иван",
      plus_two_name: "",
      url: "ivan-petya",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 31,
      invitation_is_sent: 1,
      name: "Милен",
      plus_one_name: "",
      plus_two_name: "",
      url: "milen",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 32,
      invitation_is_sent: 1,
      name: "Магда",
      plus_one_name: "Чавдар",
      plus_two_name: "",
      url: "chavdar",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 33,
      invitation_is_sent: 1,
      name: "Мария",
      plus_one_name: "Дечко",
      plus_two_name: "",
      url: "mimeto",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 34,
      invitation_is_sent: 1,
      name: "Лили",
      plus_one_name: "Антон",
      plus_two_name: "",
      url: "lili-anton",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 35,
      invitation_is_sent: 1,
      name: "Диди",
      plus_one_name: "Йоан",
      plus_two_name: "",
      url: "yoan-didi",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 36,
      invitation_is_sent: 1,
      name: "Евa",
      plus_one_name: "Иван",
      plus_two_name: "",
      url: "eva-ivan",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 37,
      invitation_is_sent: 1,
      name: "Тони Горанова",
      plus_one_name: "",
      plus_two_name: "",
      url: "goranova",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 38,
      invitation_is_sent: 1,
      name: "Златина",
      plus_one_name: "",
      plus_two_name: "",
      url: "zlati",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 39,
      invitation_is_sent: 1,
      name: "Екатерина",
      plus_one_name: "",
      plus_two_name: "",
      url: "katya",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 40,
      invitation_is_sent: 1,
      name: "Надя",
      plus_one_name: "",
      plus_two_name: "",
      url: "nadya",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 41,
      invitation_is_sent: 1,
      name: "Саша",
      plus_one_name: "",
      plus_two_name: "",
      url: "sasha",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 42,
      invitation_is_sent: 1,
      name: "Леля Данче",
      plus_one_name: "Чичо Боби",
      plus_two_name: "",
      url: "danche-bobi",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 43,
      invitation_is_sent: 1,
      name: "Христо",
      plus_one_name: "",
      plus_two_name: "",
      url: "hristo",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 44,
      invitation_is_sent: 1,
      name: "Анастасия",
      plus_one_name: "",
      plus_two_name: "",
      url: "anastasiya",
    },
    {
      cringe_section: 0,
      for_full_day: 1,
      id: 45,
      invitation_is_sent: 0,
      name: "мама Данче",
      plus_one_name: "",
      plus_two_name: "",
      url: "yordanka",
    },
    {
      cringe_section: 0,
      for_full_day: 1,
      id: 46,
      invitation_is_sent: 0,
      name: "Бабчето",
      plus_one_name: "",
      plus_two_name: "",
      url: "babche",
    },
    {
      cringe_section: 1,
      for_full_day: 1,
      id: 47,
      invitation_is_sent: 0,
      name: "Маргарита",
      plus_one_name: "",
      plus_two_name: "",
      url: "margarita",
    },
    {
      cringe_section: 1,
      for_full_day: 1,
      id: 48,
      invitation_is_sent: 1,
      name: "Кики",
      plus_one_name: "",
      plus_two_name: "",
      url: "kiki",
    },
    {
      cringe_section: 1,
      for_full_day: 1,
      id: 49,
      invitation_is_sent: 1,
      name: "Елина",
      plus_one_name: "",
      plus_two_name: "",
      url: "elina-rashkova",
    },
    {
      cringe_section: 1,
      for_full_day: 1,
      id: 50,
      invitation_is_sent: 1,
      name: "Дидо",
      plus_one_name: "",
      plus_two_name: "",
      url: "diyan-chuburov",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 51,
      invitation_is_sent: 1,
      name: "Чичо Ники",
      plus_one_name: "",
      plus_two_name: "",
      url: "niki",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 52,
      invitation_is_sent: 1,
      name: "Надежда",
      plus_one_name: "",
      plus_two_name: "",
      url: "nadezhda",
    },
    {
      cringe_section: 0,
      for_full_day: 0,
      id: 53,
      invitation_is_sent: 1,
      name: "Надя",
      plus_one_name: "Владо",
      plus_two_name: "",
      url: "chervenkovi",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 54,
      invitation_is_sent: 1,
      name: "Палмина Димитрова",
      plus_one_name: "",
      plus_two_name: "",
      url: "palmina",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 55,
      invitation_is_sent: 1,
      name: "Николай Ангелов",
      plus_one_name: "",
      plus_two_name: "",
      url: "nikolay-angelov",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 56,
      invitation_is_sent: 1,
      name: "Спас Вутов",
      plus_one_name: "",
      plus_two_name: "",
      url: "spas-vutov",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 57,
      invitation_is_sent: 1,
      name: "Пламен Тодоров",
      plus_one_name: "",
      plus_two_name: "",
      url: "plamen",
    },
    {
      cringe_section: 1,
      for_full_day: 0,
      id: 58,
      invitation_is_sent: 1,
      name: "Гената",
      plus_one_name: "",
      plus_two_name: "",
      url: "genata",
    },
  ],
  guestsLoaded: true,
};

export default guests_state;

// import { getDatabase, ref, onValue } from "firebase/database";
import { Router, Switch, Route } from "react-router-dom";
import { useStoreMe, setStoreMe } from "store-me";
// import { initializeApp } from "firebase/app";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

// import mergeMultiLineFields from "./utils/mergeMultiLineFields";
import PageLayout from "./components/pages/PageLayout";
import loadResources from "./utils/loadResources";
import Loading from "./components/pages/Loading";
import Guest from "./components/pages/Guest";

const websiteResourcesToPreload = [
  "/images/leafs-bottom-right.png",
  "/images/leafs-left.jpg",
  "/images/leafs-top-right.jpg",
  "/images/splash-bottom-left.png",
  "/images/splash-bottom-mid.png",
  "/images/splash-bottom-right.png",
];

const App = () => {
  const { guests, loaderFaded, guestsLoaded, resourcesLoaded } = useStoreMe(
    "guests",
    "loaderFaded",
    "guestsLoaded",
    "resourcesLoaded"
  );
  const history = useHistory();
  const historyRef = useRef(history);

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  // useEffect(function loadDatabase() {
  //   const project = "wedding-c4427";
  //   const firebaseConfig = {
  //     appId: "1005482975504",
  //     projectId: project,
  //     authDomain: `${project}.firebaseapp.com`,
  //     storageBucket: `${project}.appspot.com`,
  //     databaseURL: `https://${project}-default-rtdb.europe-west1.firebasedatabase.app/`,
  //   };
  //   const app = initializeApp(firebaseConfig);
  //   const database = getDatabase(app);
  //   const starCountRef = ref(database);

  //   onValue(starCountRef, (snapshot) => {
  //     const spreadSheets = snapshot.val();
  //     let newState = {};

  //     for (let spreadSheetID in spreadSheets) {
  //       const spreadSheet = spreadSheets[spreadSheetID];

  //       for (let sheetName in spreadSheet) {
  //         const sheet = spreadSheet[sheetName];

  //         if (sheetName === "guests") {
  //           newState.guests = sheet.filter((data) => Boolean(data));
  //           newState.guestsLoaded = true;
  //         }

  //         if (sheetName === "about") {
  //           newState = { ...mergeMultiLineFields(sheet) };
  //         }
  //       }
  //     }

  //     if (Object.keys(newState).length) {
  //       setStoreMe(newState);
  //     }

  //     console.log(newState);
  //   });
  // }, []);

  useEffect(
    function loadStaticFiles() {
      if (guestsLoaded) {
        loadResources(websiteResourcesToPreload)
          .then(() => setStoreMe({ resourcesLoaded: true }))
          .catch(() => {});
      }
    },
    [guestsLoaded]
  );

  useEffect(
    function handleNonExistingLinkRedirect() {
      if (guests.length) {
        const { pathname } = window.location;

        if (pathname && pathname !== "/") {
          const routeFound = guests.find(
            (route) => `/${route.url}` === pathname
          );

          if (!routeFound) {
            historyRef.current.replace({
              pathname: "/",
            });
          }
        }
      }
    },
    [guests]
  );

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <PageLayout>
            {!loaderFaded && <Loading />}
            <Guest />
          </PageLayout>
        </Route>

        {resourcesLoaded &&
          loaderFaded &&
          guests.map((guest) => (
            <Route path={`/${guest.url}`} key={guest.url}>
              <PageLayout {...guest}>
                <Guest {...guest} />
              </PageLayout>
            </Route>
          ))}

        <Route>
          <Loading />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;

import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useStoreMe } from "store-me";
import cn from "classnames";

// import NavigationIcon from "../../icons/NavigationIcon";
import { rcn, isMobile } from "../../utils/responsive";
// import TwoLeafsIcon from "../../icons/TwoLeafsIcon";
// import CursorIcon from "../../icons/CursorIcon";
import SuitIcon from "../../icons/SuitIcon";
import BowIcon from "../../icons/BowIcon";
// import Button from "../common/Button";
import i18n from "../../utils/i18n";

const Guest = (props) => {
  const {
    loaderFaded,
    fullNames,
    weddingDateWithLetters,
    bestMan,
    bestWoman,
    // party_hour,
    // signatures_hour,
    // ritualHallName,
    // ritualHallAddress,
    // weddingPlaceName,
    // weddingPlaceAddress,
    // event_space_location,
    // signature_room_location,
    // google_form,
    invite_headline,
    invite_long_text_multiple_guests,
    invite_long_text_single_guest,
    invite_welcome_multiple_guests,
    invite_welcome_single_guest,
  } = useStoreMe(
    "loaderFaded",
    "fullNames",
    "weddingDateWithLetters",
    "bestMan",
    "bestWoman",
    // "party_hour",
    // "signatures_hour",
    // "ritualHallName",
    // "ritualHallAddress",
    // "weddingPlaceName",
    // "weddingPlaceAddress",
    // "event_space_location",
    // "signature_room_location",
    // "google_form",
    "invite_headline",
    "invite_long_text_multiple_guests",
    "invite_long_text_single_guest",
    "invite_welcome_multiple_guests",
    "invite_welcome_single_guest"
  );
  const [animations, setAnimations] = useState({});
  const { animation_1, animation_2, animation_3, animation_4 } = animations;
  // const { name, plus_one_name, plus_two_name, for_full_day } = props;
  const { name, plus_one_name, plus_two_name } = props;
  const guests = [name, plus_one_name, plus_two_name].filter(Boolean);
  const guestNames = guests.join(", ").replace(/,([^,]*)$/, " и$1");
  const isSingleGuest = guests.length === 1;
  const timerRef = useRef();

  const startAnimation = (number, delayMS) => {
    const set = () =>
      setAnimations((prev) => ({
        ...prev,
        [`animation_${number}`]: true,
      }));

    if (delayMS) {
      timerRef.current = setTimeout(() => set(), delayMS);
    } else {
      set();
    }
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (loaderFaded) {
      if (name) {
        startAnimation(1);
      } else {
        startAnimation(3);
        startAnimation(2);
      }
    }
  }, [loaderFaded, name]);

  return (
    <Wrap className={rcn}>
      <BackgroundWrap>
        <TopLeftAnimation className={cn(rcn, { animate: animation_2 })} />
        <TopRightAnimation className={cn(rcn, { animate: animation_2 })} />
        <SplashBottomRight className={cn(rcn, { animate: animation_2 })} />
        <BottomRightAnimation className={cn(rcn, { animate: animation_2 })} />
        <SplashBottomLeft className={cn(rcn, { animate: animation_2 })} />
        <SplashBottomMid className={cn(rcn, { animate: animation_2 })} />
      </BackgroundWrap>

      <ContentWrap>
        <TopWrap>
          {name && (
            <InviteHeadline
              className={cn({ animate: animation_1 })}
              onAnimationEnd={({ animationName }) =>
                animationName === "fade-in-slide-up" && startAnimation(2, 300)
              }
            >
              {invite_headline}
            </InviteHeadline>
          )}

          {name && (
            <Guests
              className={cn(rcn, "handy", { animate: animation_2 })}
              onAnimationEnd={({ animationName }) =>
                animationName === "fade-in-slide-up" && startAnimation(3, 300)
              }
            >
              {guestNames}
            </Guests>
          )}

          <InviteSubheadline
            className={cn(rcn, { animate: animation_3 })}
            onAnimationEnd={({ animationName }) =>
              animationName === "fade-in-slide-up" && startAnimation(4, 800)
            }
          >
            {isSingleGuest
              ? invite_welcome_single_guest
              : invite_welcome_multiple_guests}
          </InviteSubheadline>

          <OurNames className={cn(rcn, "handy", { animate: animation_4 })}>
            {fullNames}
          </OurNames>

          <WeddingDate
            className={cn("txt-gold-gradient", { animate: animation_4 })}
          >
            {weddingDateWithLetters}
          </WeddingDate>

          {/* <LocationsWrap
            className={cn(rcn, {
              "for-full-day": Boolean(for_full_day),
              animate: animation_4,
            })}
          >
            {Boolean(for_full_day) && (
              <div className="ritual-hall">
                <div>{signatures_hour}ч.</div>
                <div>{ritualHallName}</div>
                <div>
                  <a
                    href={signature_room_location}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ritualHallAddress}
                  </a>
                  <a
                    href={signature_room_location}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledNavigationIcon />
                  </a>
                </div>
              </div>
            )}

            {Boolean(for_full_day) && isMobile && <StyledTwoLeafsIcon />}

            <div className="wedding-place">
              <div>{party_hour}.ч</div>
              <div>{weddingPlaceName}</div>
              <div>
                <a href={event_space_location} target="_blank" rel="noreferrer">
                  {weddingPlaceAddress}
                </a>
                <a href={event_space_location} target="_blank" rel="noreferrer">
                  <StyledNavigationIcon />
                </a>
              </div>
            </div>
          </LocationsWrap> */}

          <WelcomeNote className={cn({ animate: animation_4 })}>
            {isSingleGuest && i18n(invite_long_text_single_guest)}
            {!isSingleGuest && i18n(invite_long_text_multiple_guests)}
          </WelcomeNote>

          {/* <StyledButton
            href={google_form}
            className={cn({ animate: animation_4 })}
            text={
              guests.length === 1
                ? "Потвърди присъствие тук"
                : "Потвърдете присъствие тук"
            }
          >
            <StyledCursorIcon />
          </StyledButton> */}
        </TopWrap>

        <BottomWrap className={cn(rcn, { animate: animation_4 })}>
          <LeftBottomWrap>
            <StyledBowIcon />
            <span>Кума:</span> {bestWoman}
          </LeftBottomWrap>

          {!isMobile && <MidBottomWrap />}

          <RightBottomWrap className={rcn}>
            <StyledSuitIcon />
            <span>Кум:</span> {bestMan}
          </RightBottomWrap>
        </BottomWrap>
      </ContentWrap>
    </Wrap>
  );
};

export default Guest;

const Wrap = styled.div`
  position: relative;
  display: flex;
  min-height: 550px;
  background-color: #fff;
  font-size: 32px;
  color: #807d7d;
  text-align: center;
  border-bottom: 1px solid #ffd23c;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);

  &.is-mobile {
    word-break: break-word;
    min-height: auto;
    height: auto;
    padding: 60px 20px 20px 20px;
  }
`;

const BackgroundWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    position: absolute;
    opacity: 0;
  }
`;

const TopLeftAnimation = styled.div`
  top: -20px;
  left: -20px;
  width: 356px;
  height: 662px;
  background: url(./images/leafs-left.jpg) no-repeat;
  background-size: contain;

  &.animate {
    animation: left-flower-animation 0.8s ease-out forwards;
  }

  &.is-mobile {
    top: 0;
    left: 0;
    width: 35vw;
    height: 63vw;
  }
`;

const TopRightAnimation = styled.div`
  top: -20px;
  right: -20px;
  width: 139px;
  height: 390px;
  background: url(./images/leafs-top-right.jpg) no-repeat;
  background-size: contain;

  &.animate {
    animation: right-flower-animation 0.8s ease-out forwards;
  }

  &.is-mobile {
    top: 0;
    right: 0;
    width: 25vw;
    height: 70vw;
  }
`;

const BottomRightAnimation = styled.div`
  top: auto;
  bottom: 70px;
  right: 0;
  width: 427px;
  height: 409px;
  background: url(./images/leafs-bottom-right.png) no-repeat;
  background-size: contain;

  &.animate {
    animation: right-flower-animation 0.8s ease-out forwards;
  }

  &.is-mobile {
    display: none;
  }
`;

const SplashBottomLeft = styled.div`
  top: auto;
  bottom: 90px;
  left: 0;
  width: 273px;
  height: 315px;
  background: url(./images/splash-bottom-left.png) no-repeat;
  background-size: contain;

  &.animate {
    animation: fade-in 0.8s ease-out forwards;
  }

  &.is-mobile {
    top: 65vw;
    left: -7vw;
    width: 35vw;
    height: 35vw;
  }
`;

const SplashBottomMid = styled.div`
  top: auto;
  bottom: 0px;
  left: 50%;
  width: 446px;
  height: 115px;
  background: url(./images/splash-bottom-mid.png) no-repeat;
  background-size: contain;

  &.animate {
    animation: fade-in 0.8s ease-out forwards;
  }

  &.is-mobile {
    right: 0;
    left: auto;
    width: 70vw;
    height: 15vw;
  }
`;

const SplashBottomRight = styled.div`
  top: auto;
  bottom: 117px;
  right: 0;
  width: 316px;
  height: 446px;
  background: url(./images/splash-bottom-right.png) no-repeat;
  background-size: contain;

  &.animate {
    animation: fade-in 0.8s ease-out forwards;
  }

  &.is-mobile {
    bottom: 207px;
    width: 35vw;
    height: 60vw;
  }
`;

const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 10;
`;

const TopWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const InviteHeadline = styled.div`
  font-size: 25px;
  opacity: 0;

  &.animate {
    animation: fade-in-slide-up 0.4s ease-out forwards;
  }
`;

const Guests = styled.div`
  font-size: 47px;
  padding: 12px 0 0 0;
  color: #4f8c77;
  opacity: 0;

  &.animate {
    animation: fade-in-slide-up 0.8s ease-out forwards;
  }

  &.is-mobile {
    font-size: 40px;
    padding: 30px 0 0 0;
  }
`;

const InviteSubheadline = styled.div`
  font-size: 25px;
  padding: 18px 0 0 0;
  opacity: 0;

  &.animate {
    animation: fade-in-slide-up 0.4s ease-out forwards;
  }

  &.is-mobile {
    padding: 25px 0 0 0;
  }
`;

const OurNames = styled.div`
  opacity: 0;
  font-size: 80px;
  padding: 40px 0;
  color: #4f8c77;

  &.animate {
    animation: fade-in 1s ease-out forwards;
  }

  &.is-mobile {
    font-size: 60px;
    word-spacing: 2000px;
    padding: 25px 0 40px 0;
  }
`;

const WeddingDate = styled.div`
  color: #e9b600;
  opacity: 0;

  &.animate {
    animation: fade-in 0.3s ease forwards;
  }
`;

// const LocationsWrap = styled.div`
//   width: 100%;
//   padding: 40px 0;
//   font-size: 20px;
//   line-height: 25px;
//   opacity: 0;

//   &.animate {
//     animation: fade-in 0.3s ease forwards;
//   }

//   & > div {
//     display: inline-block;
//     text-align: center;
//     width: 100%;
//   }

//   a {
//     color: #807d7d;
//     transition: color 0.3s ease;

//     &:hover {
//       color: #d6a800;
//     }
//   }

//   &.for-full-day {
//     & > div {
//       width: 50%;
//     }

//     .ritual-hall {
//       border-right: 1px solid #999;
//       padding-right: 25px;
//       text-align: right;
//     }

//     .wedding-place {
//       text-align: left;
//       padding-left: 25px;
//     }
//   }

//   &.is-mobile {
//     padding: 50px 0;

//     &.for-full-day {
//       & > div {
//         width: 100%;
//       }

//       .ritual-hall {
//         padding-right: 0;
//         border-right: none;
//         text-align: center;
//       }

//       .wedding-place {
//         text-align: center;
//         padding-left: 0;
//       }
//     }
//   }
// `;

// const StyledTwoLeafsIcon = styled(TwoLeafsIcon)`
//   fill: #7db09f;
//   width: auto;
//   height: 30px;
//   margin: 15px 0 10px 0;
// `;

const WelcomeNote = styled.div`
  padding: 40px 0 25px 0;
  max-width: 660px;
  text-align: center;
  font-size: 17px;
  line-height: 23px;
  opacity: 0;

  &.animate {
    animation: fade-in 0.3s ease forwards;
  }

  a {
    color: #4f8c77;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: #4f8c77;
    }
  }
`;

// const StyledButton = styled(Button)`
//   padding: 17px 42px 17px 20px;
//   opacity: 0;
//   transition: all 0.3s ease;

//   &.animate {
//     animation: fade-in 0.3s ease forwards;
//   }

//   &:hover {
//     color: #d6a800;
//     border-color: #d6a800;
//     background-color: #fff;
//     transform: translateY(-2px);
//     box-shadow: 0px 8px 20px rgb(0 0 0 / 10%);

//     svg {
//       polygon {
//         stroke: #e9b600;
//       }
//     }
//   }
// `;

// const StyledNavigationIcon = styled(NavigationIcon)`
//   height: 17px;
//   width: auto;
//   padding-left: 10px;
// `;

// const StyledCursorIcon = styled(CursorIcon)`
//   position: absolute;
//   right: 12px;
//   top: 16px;
//   width: auto;
//   height: 22px;

//   polygon {
//     stroke: #638468;
//     transition: stroke 0.3s ease;
//   }
// `;

const BottomWrap = styled.div`
  display: flex;
  font-size: 20px;
  padding: 0 25px 10px 25px;
  opacity: 0;
  color: #43544a;

  &.animate {
    animation: fade-in 0.3s ease forwards;
  }

  & > div {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &.is-mobile {
    padding: 30px 0 0 0;
    flex-direction: column;
  }
`;

const LeftBottomWrap = styled.div`
  span {
    color: #106a4b;
    color: #e9b600;
    padding-right: 10px;
  }
`;

const MidBottomWrap = styled.div`
  justify-content: center;
  align-items: center;
`;

const RightBottomWrap = styled.div`
  align-items: end;
  justify-content: end;

  span {
    color: #106a4b;
    color: #e9b600;
    padding-right: 10px;
  }

  &.is-mobile {
    padding-top: 20px;
    justify-content: normal;
  }
`;

const StyledSuitIcon = styled(SuitIcon)`
  width: auto;
  height: 60px;
  fill: #106a4b;
  margin-right: 10px;
`;

const StyledBowIcon = styled(BowIcon)`
  width: auto;
  height: 60px;
  fill: #106a4b;
  margin-right: 10px;
`;

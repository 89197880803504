const BowIcon = ({ className }) => (
  <svg
    className={className}
    enableBackground="new 0 0 64 64"
    height="64px"
    version="1.1"
    viewBox="0 0 64 64"
    width="64px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="bow_2_">
      <g>
        <g id="bow_10_">
          <g>
            <g id="bow_x5F_element4_2_">
              <path d="M52.481,5.442c-0.255-0.358-0.756-0.786-1.699-0.786c-3.724,0-12.314,7.116-16.32,11.7       c0.303,0.187,0.547,0.433,0.726,0.723c3.776-4.308,12.201-11.423,15.594-11.423c0.561,0,0.772,0.208,0.902,0.389       c0.138,0.172,0.211,0.369,0.232,0.589c0.159-0.029,0.323-0.05,0.5-0.05c0.18,0,0.346,0.021,0.506,0.05       C52.897,6.101,52.693,5.704,52.481,5.442z" />
            </g>
            <path d="M52.352,5.394c-0.195-0.161-0.483-0.15-0.665,0.026c-0.184,0.176-0.205,0.461-0.051,0.663      c0.176,0.229,0.137,0.604-0.115,1.115c-1.617,3.273-10.773,9.396-16.253,10.672c0.196,0.275,0.333,0.589,0.407,0.922      c5.835-1.464,15.028-7.679,16.743-11.151c0.101-0.202,0.174-0.391,0.226-0.566c4.04,4.614,5.997,15.721,5.203,19.801      C56.455,29.27,51.376,31,45.711,31c-2.66,0-5.145-0.384-7.357-1.093c-0.019,0.094-0.054,0.181-0.083,0.271      c0.05,0.247,0.066,0.5,0.05,0.753C40.564,31.619,43.053,32,45.711,32c6.156,0,11.521-1.936,13.048-4.707      c0.023-0.044,0.041-0.091,0.051-0.14C59.769,22.533,57.384,9.545,52.352,5.394z" />
          </g>
        </g>
        <g id="bow_9_">
          <g>
            <g id="bow_x5F_element4_1_">
              <path d="M12.307,7.494c-0.245-0.606-0.22-1.08,0.096-1.474c0.111-0.156,0.323-0.364,0.884-0.364       c3.383,0,11.777,7.079,15.573,11.393c0.151-0.311,0.36-0.591,0.637-0.809C25.451,11.653,16.98,4.656,13.287,4.656       c-0.944,0-1.445,0.428-1.681,0.762c-0.37,0.459-0.71,1.256-0.226,2.451c1.594,3.937,11.049,9.55,16.874,11.438       c-0.006-0.336,0.042-0.673,0.152-0.987C22.643,16.451,13.692,10.914,12.307,7.494z" />
            </g>
            <path d="M25.589,29.958C23.407,30.636,20.965,31,18.358,31h-0.001c-5.665,0-10.744-1.73-12.135-4.125      c-0.793-4.08,1.164-15.188,5.203-19.801c0.041,0.14,0.108,0.293,0.179,0.449c-0.074-0.781,0.17-1.59,0.733-2.133      c-0.183-0.142-0.438-0.146-0.621,0.003c-5.031,4.15-7.416,17.14-6.458,21.76c0.01,0.049,0.028,0.096,0.052,0.14      C6.836,30.064,12.201,32,18.357,32c0.001,0,0,0,0.001,0c2.67,0,5.188-0.363,7.444-1.053      C25.672,30.637,25.599,30.301,25.589,29.958z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M44.069,31.447c4.91,3.841,8.798,10.364,10.583,18.438l-7.449-2.108l-4.664,8.149      c-1.372-8.813-5.495-18.892-8.955-23.69c-0.392,0.041-0.784,0.051-1.176,0.062c3.56,4.566,8.22,16.016,9.342,25.208L41.932,59      l5.752-10.047l8.28,2.343l-0.161-0.803C54.17,42.385,50.47,35.706,45.7,31.499C45.159,31.499,44.615,31.48,44.069,31.447z" />
            <path d="M21.549,55.927l-4.665-8.149l-7.448,2.108c1.785-8.075,5.675-14.6,10.585-18.439      c-0.547,0.033-1.092,0.052-1.634,0.053c-4.77,4.207-8.47,10.886-10.102,18.994l-0.162,0.803l8.281-2.343L22.156,59l0.182-1.494      c1.122-9.19,5.779-20.634,9.339-25.203c-0.392-0.009-0.784-0.023-1.175-0.063C27.042,37.042,22.921,47.116,21.549,55.927z" />
          </g>
          <g>
            <path d="M32,32.819c-2.197,0-4.422-0.47-6.613-1.396l-0.34-0.143l0.037-0.367      c0.417-4.127,1.589-8.82,3.688-14.768l0.081-0.229l0.231-0.078c1.932-0.651,3.9-0.651,5.832,0l0.23,0.078l0.081,0.229      c2.099,5.947,3.271,10.64,3.688,14.768l0.037,0.367l-0.34,0.143C36.422,32.35,34.197,32.819,32,32.819z M26.118,30.645      c3.914,1.561,7.853,1.56,11.764,0c-0.435-3.904-1.557-8.35-3.516-13.932c-1.574-0.482-3.157-0.482-4.733,0      C27.675,22.295,26.552,26.741,26.118,30.645z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BowIcon;

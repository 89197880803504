import Carousel, { Modal, ModalGateway } from "react-images";
import { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";

const buildImageCollection = (ctx, path) =>
  ctx.keys().map((image) => ({
    src: `${path}${image}`,
    width: 300,
    height: 300,
  }));

const firstLookCtx = require.context(
  "../../../public/images/wedding/first-look",
  false,
  /\.(png|jpe?g|svg)$/
);
const ceremonyCtx = require.context(
  "../../../public/images/wedding/ceremony",
  false,
  /\.(png|jpe?g|svg)$/
);
const gardenCtx = require.context(
  "../../../public/images/wedding/garden",
  false,
  /\.(png|jpe?g|svg)$/
);
const partyCtx = require.context(
  "../../../public/images/wedding/party",
  false,
  /\.(png|jpe?g|svg)$/
);
const photoBoothCtx = require.context(
  "../../../public/images/wedding/photo-booth",
  false,
  /\.(png|jpe?g|svg)$/
);

const photosMap = {
  first_look: buildImageCollection(
    firstLookCtx,
    "./images/wedding/first-look/"
  ),
  ceremony: buildImageCollection(ceremonyCtx, "./images/wedding/ceremony/"),
  garden: buildImageCollection(gardenCtx, "./images/wedding/garden/"),
  party: buildImageCollection(partyCtx, "./images/wedding/party/"),
  photo_booth: buildImageCollection(
    photoBoothCtx,
    "./images/wedding/photo-booth/"
  ),
};

console.log(photosMap);

const WeddingGallery = ({ galleryType = "ceremony", className }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const photos = photosMap[galleryType];

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className={className}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default WeddingGallery;

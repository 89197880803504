// import { useStoreMe } from "store-me";
import styled from "styled-components";
import cn from "classnames";

// import PresentBoxIcon from "../../icons/PresentBoxIcon";
// import PhoneHeartIcon from "../../icons/PhoneHeartIcon";
// import TwoLeafsIcon from "../../icons/TwoLeafsIcon";
import WeddingGallery from "../common/WeddingGallery";
// import FlowersIcon from "../../icons/FlowersIcon";
import { rcn } from "../../utils/responsive";
// import i18n from "../../utils/i18n";

const PageLayout = ({ children, ...guest }) => {
  // const { cringe_section } = guest;
  // const {
  //   notes_books_instead_of_flowers,
  //   note_cash_is_welcome,
  //   notes_save_this_link,
  //   notes_good_to_know,
  // } = useStoreMe(
  //   "notes_books_instead_of_flowers",
  //   "note_cash_is_welcome",
  //   "notes_save_this_link",
  //   "notes_good_to_know"
  // );

  return (
    <Wrap>
      {children}

      <BottomPageWrap>
        {/* <WeddingInfoWrap className={rcn}>
          <InfoBox className={rcn}>
            <InfoIconWrap className={rcn}>
              <StyledPresentBoxIcon />
            </InfoIconWrap>
            <div>{i18n(note_cash_is_welcome)}</div>
          </InfoBox>

          <InfoBox className={rcn}>
            <InfoIconWrap className={rcn}>
              <StyledFlowersIcon />
            </InfoIconWrap>
            <div>{i18n(notes_books_instead_of_flowers)}</div>
          </InfoBox>

          <InfoBox className={rcn}>
            <InfoIconWrap className={rcn}>
              <StyledPhoneHeartIcon />
            </InfoIconWrap>
            <div>{i18n(notes_save_this_link)}</div>
          </InfoBox>
        </WeddingInfoWrap>

        <GeneralWeddingInfo className={rcn}>
          {i18n(notes_good_to_know, {
            displayAsList: true,
            prefix: (
              <BulletIconWrap>
                <TwoLeafsIcon />
              </BulletIconWrap>
            ),
          })}
        </GeneralWeddingInfo>

        {Boolean(cringe_section) && (
          <GalleryHeadline className={rcn}>
            Here is some <span className="handy">Cringe</span> for you
          </GalleryHeadline>
        )}

        {!Boolean(cringe_section) && (
          <GalleryHeadline className={rcn}>
            Част от <span className="handy">нашата</span> история
          </GalleryHeadline>
        )} */}

        <GalleryHeadline className={cn("handy", rcn)}>
          First look/Първо виждане
        </GalleryHeadline>

        <WeddingGallery galleryType="first_look" />

        <GalleryHeadline className={cn("handy", rcn)}>
          Ceremony/Церемония
        </GalleryHeadline>

        <WeddingGallery galleryType="ceremony" />

        <GalleryHeadline className={cn("handy", rcn)}>
          Garden/Градина
        </GalleryHeadline>

        <WeddingGallery galleryType="garden" />

        <GalleryHeadline className={cn("handy", rcn)}>
          Party/Парти
        </GalleryHeadline>

        <WeddingGallery galleryType="party" />

        <GalleryHeadline className={cn("handy", rcn)}>
          Photo booth/Фото будка
        </GalleryHeadline>

        <WeddingGallery galleryType="photo_booth" />
      </BottomPageWrap>

      <FadeCover />
    </Wrap>
  );
};

export default PageLayout;

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: #f5fdf9; */
`;

const BottomPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3e5047;
  line-height: 23px;
`;

// const WeddingInfoWrap = styled.div`
//   display: flex;
//   padding: 0 20px;

//   &.is-mobile {
//     flex-direction: column;
//   }
// `;

// const InfoBox = styled.div`
//   display: flex;
//   flex: 1 1 0px;
//   align-items: center;

//   &.is-mobile {
//     flex-direction: column;
//     text-align: center;
//     margin-top: 40px;

//     &:first-child {
//       margin-top: 0;
//     }
//   }
// `;

// const InfoIconWrap = styled.div`
//   margin-right: 15px;

//   &.is-mobile {
//     margin-right: 0;
//   }
// `;

// const GeneralWeddingInfo = styled.div`
//   display: flex;
//   width: 100%;
//   margin: 15px 0 0 0;
//   padding: 30px 20px;
//   border-top: 1px solid #b3d6c3;

//   ul {
//     display: flex;
//     list-style: none;
//     margin: 0;
//     padding: 0;

//     li {
//       display: flex;
//       align-items: flex-start;
//       padding-right: 15px;
//     }
//   }

//   svg {
//     fill: #7db09f;
//     width: auto;
//     height: 30px;
//   }

//   &.is-mobile {
//     ul {
//       flex-direction: column;

//       li {
//         flex-direction: column;
//         padding: 0;
//         text-align: center;
//         align-items: center;
//         margin-top: 25px;

//         &:first-child {
//           margin-top: 0;
//         }
//       }
//     }
//   }
// `;

// const BulletIconWrap = styled.div`
//   margin-right: 10px;
// `;

// const StyledPresentBoxIcon = styled(PresentBoxIcon)`
//   fill: #7db09f;
//   width: auto;
//   height: 75px;
// `;

// const StyledFlowersIcon = styled(FlowersIcon)`
//   fill: #7db09f;
//   width: auto;
//   height: 90px;
// `;

// const StyledPhoneHeartIcon = styled(PhoneHeartIcon)`
//   fill: #7db09f;
//   width: auto;
//   height: 95px;
// `;

const FadeCover = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #fff;
  pointer-events: none;
  animation: fade-out 0.3s ease forwards;
`;

const GalleryHeadline = styled.div`
  font-size: 43px;
  padding: 130px 0;

  span {
    color: #4f8c77;
    font-size: 40px;
    padding: 0 5px 0 2px;
  }

  &.is-mobile {
    font-size: 23px;
    padding: 10px 10px 30px 10px;
    text-align: center;
  }
`;

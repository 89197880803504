import { useStoreMe, setStoreMe, getStoreMe } from "store-me";
import { useState, useEffect } from "react";
import styled from "styled-components";
import cn from "classnames";

const Loading = () => {
  const { weddingDate } = useStoreMe("weddingDate");
  const [introImageLoaded, setIntroImageLoaded] = useState();
  const [removeLoader, setRemoveLoader] = useState();

  useEffect(() => {
    let msPassed = 0;
    const intervalMs = 500;
    const minimumStayTimeMs = 2000;
    const interval = setInterval(() => {
      const { resourcesLoaded, guestsLoaded } = getStoreMe(
        "resourcesLoaded",
        "guestsLoaded"
      );

      msPassed += intervalMs;

      if (msPassed >= minimumStayTimeMs && guestsLoaded && resourcesLoaded) {
        setRemoveLoader(true);
        clearInterval(interval);
      }
    }, intervalMs);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrap>
      <LoaderWrap>
        <LoaderImage
          src="/images/loader.jpg"
          className={cn({ animate: introImageLoaded })}
          width={328}
          onLoad={() => setIntroImageLoaded(true)}
          alt=""
        />
        <LoaderContent>
          <span className="handy">
            Л <span className="dark-gold">+</span> М
          </span>
          <b>
            {weddingDate
              .split("")
              .map((char, i) => (char === "." ? <i key={i}>.</i> : char))}
          </b>
        </LoaderContent>
      </LoaderWrap>

      <FadeCover
        className={cn({
          "fade-in": removeLoader,
          "fade-out": !removeLoader && introImageLoaded,
        })}
        onAnimationEnd={({ animationName }) => {
          animationName === "fade-in" && setStoreMe({ loaderFaded: true });
        }}
      />
    </Wrap>
  );
};

export default Loading;

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 40px;
  background-color: #fff;
`;

const LoaderWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderImage = styled.img`
  &.animate {
    animation: loader-spin 1.3s ease forwards;
  }
`;

const LoaderContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #727272;

  span {
    font-size: 38px;
    padding-top: 20px;
  }

  b {
    font-weight: 100;
    font-size: 18px;
    padding-top: 9px;

    i {
      padding: 0 2px;
    }
  }
`;

const FadeCover = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #fff;

  &.fade-in {
    animation: fade-in 0.5s ease forwards;
  }

  &.fade-out {
    animation: fade-out 0.4s ease forwards;
  }
`;
